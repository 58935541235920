import React, { useState } from 'react';
import axios from 'axios';
import './payment.css';
import { Link, useNavigate, useParams } from 'react-router-dom';

const CompileAndDeployComponent = (props) => {
    const [response, setResponse] = useState('');
    const [_ContractName, set_ContractName] = useState('');
    const [_ContractSymbol, set_ContractSymbol] = useState('');
    const [_TotalSupply, set_TotalSupply] = useState('');
    const [_TokenTransferAddress, set_TokenTransferAddress] = useState('');
    const userAddress = props.userAddress;
    const getLastPaymentRecordIndex = props.getLastPaymentRecordIndex;
    const navigate = useNavigate();

    const handleCompileAndDeploy = async () => {
        try {
            // Trigger the compilation first
            const compileResponse = await axios.post('https://web3deploy.com/compile');
            //http://localhost:3001/deploy

            console.log("compileResponse : " + compileResponse.data);

            if (compileResponse.data === 'Compilation successful' || compileResponse.data === 'Contracts are already compiled. Move to deployment') {
                console.log("Deploying....");
                setResponse("Deploying & Verifying....");

                // If compilation is successful, trigger the deployment
                const deployResponse = await axios.post('https://web3deploy.com/deploy', {
                    //http://localhost:3001/deploy

                    contractName: _ContractName,
                    contractSymbol: _ContractSymbol,
                    totalSupply: _TotalSupply,
                    tokenTransferAddress: _TokenTransferAddress,
                    userAddress: userAddress,
                    getLastPaymentRecordIndex: Number(getLastPaymentRecordIndex),

                });

                if (deployResponse.data === 'Deployment successful') {
                    setResponse('Contract deployed & verified successfully.');
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    navigate(`/dashboard`);
                } else {
                    setResponse(`Error during deployment: ${deployResponse.data}`);
                }
            } else {
                setResponse(`Error during compilation: ${compileResponse.data}`);
            }

        } catch (error) {
            console.error('Error during compilation and deployment:', error.message);
            setResponse(`Error during compilation and deployment: ${error.message}`);
        }
    };



    return (

        <>
            <h1>when user inputs data, deployment will happen here</h1>
            <div className='mainDiv'>

                <div>
                    <input required class="mintingAmount" type="text" name="_ContractName" placeholder="Contract Name" value={_ContractName}
                        onChange={event => set_ContractName(event.target.value)}></input>
                    <input required class="mintingAmount" type="text" name="_ContractSymbol" placeholder="Contract Symbol" value={_ContractSymbol}
                        onChange={event => set_ContractSymbol(event.target.value)}></input>
                    <input required class="mintingAmount" type="number" name="_TotalSupply" placeholder="Total Supply" value={_TotalSupply}
                        onChange={event => set_TotalSupply(event.target.value)}></input>
                    <input required class="mintingAmount" type="text" name="_TokenTransferAddress" placeholder="Token Transfer Address" value={_TokenTransferAddress}
                        onChange={event => set_TokenTransferAddress(event.target.value)}></input>
                </div>

                <button onClick={handleCompileAndDeploy}>Compile and Deploy Contract</button>

                <div className='note'>{response}</div>

            </div>
        </>
    );
};

export default CompileAndDeployComponent;
