import React, { useState, useEffect } from 'react';
import CompileComponent from './components/CompileComponent';
import Register from './components/Register';
import '../src/components/payment.css';
import { paymentContractAddress } from '../src/config';
import TwitterAbi from '../src/abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import logo from '../src/images/logo.png';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import { Link } from 'react-router-dom';
import Home from './pages/Home';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [paymentMade, setPaymentMade] = useState(false);
  const notification = useNotification();
  const [loading, setLoadingState] = useState(false);
  const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
  const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
  const [provider, setProvider] = useState(window.ethereum);
  const [_signerAddress, setSignerAddress] = useState();

  const handlePaymentMade = () => {
    setPaymentMade(true);
  };

  const warningNotification = () => {
    notification({
      type: 'warning',
      message: 'Change network to Polygon Mumbai to visit this site',
      title: 'Switch to Polygon Mumbai Test Network',
      position: 'topR'
    });
  };

  const infoNotification = (accountNum) => {
    notification({
      type: 'info',
      message: accountNum,
      title: 'Connected to Polygon Mumbai Test Network Network',
      position: 'topR'
    });
  };

  const checkEligibility = async () => {

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      //  let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      setSignerAddress(signerAddress);
      const contract = new ethers.Contract(paymentContractAddress, TwitterAbi.abi, signer);

      const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
      setIsEligibleToDeploy(isEligibleToDeploy);
    } catch (err) {
      console.log(err);
    }
  }

  const getLastPaymentRecordIndex = async () => {

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      //  let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      setSignerAddress(signerAddress);
      const contract = new ethers.Contract(paymentContractAddress, TwitterAbi.abi, signer);

      const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
      setLastPaymentRecordIndex(getLastPaymentRecordIndex);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {

    if (!provider) {
      // window.open("https://metamask.app.link/dapp/kudeta.io/");
      //window.location.replace("https://metamask.io");

      // Render something or redirect as needed
    } else {
      connectWallet();

      checkEligibility();
      getLastPaymentRecordIndex();

      const handleAccountsChanged = (accounts) => {
        if (provider.chainId == "0x13881") {
          infoNotification(accounts[0]);
        }
        // Just to prevent reloading for the very first time
        if (JSON.parse(localStorage.getItem('activeAccount')) != null) {
          setTimeout(() => { window.location.reload() }, 3000);
        }
      };

      const handleChainChanged = (chainId) => {
        if (chainId != "0x13881") {
          infoNotification();
        }
        window.location.reload();
      }

      const handleDisconnect = () => { };


      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

    }

  }, []);


  const connectWallet = async () => {

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();
        const goerliChainId = 80001;

        if (getnetwork.chainId != goerliChainId) {
          warningNotification();
          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(goerliChainId) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(goerliChainId),
                      chainName: 'Mumbai',
                      rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
                      blockExpolrerUrls: ['https://mumbai.polygonscan.com'],
                      nativeCurrency:
                      {
                        decimals: 18,
                        symbol: 'MATIC'
                      }
                    }
                  ]
                }.then(() => window.location.reload()));
              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {
          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);

          const contract = new ethers.Contract(paymentContractAddress, TwitterAbi.abi, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }
      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }
  }

  return (
    <>

      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/' element={<Home />} />
      </Routes>

    </>
  );
};

export default App;
