import React, { useState, useEffect } from "react";
import { ethers } from 'ethers';
import "../components/payment.css";
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import Web3Modal from "web3modal";
import { Link } from 'react-router-dom';
import axios from 'axios';

const Dashboard = () => {
    const [deployments, setDeployments] = useState([]);
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();
    const [response, setResponse] = useState('');

    async function verify(contractAddress) {
        try {
            console.log("contractAddress : " + contractAddress);
            const verifyResponse = await axios.post('http://localhost:3001/verify', 
            { contractAddress: contractAddress 
            });

            if(verifyResponse.data == 'Verified successfully'){
                setResponse('Contract Verified successfully.');
            } else{
                setResponse('Contract Verified Failed.');

            }


        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // Function to fetch deployment data
        const getAllPayments = async () => {
            try {

                const web3Modal = new Web3Modal();
                const connection = await web3Modal.connect();
                const provider = new ethers.providers.Web3Provider(connection);
                //  let provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const signerAddress = await signer.getAddress();
                setSignerAddress(signerAddress);
                const contract = new ethers.Contract(paymentContractAddress, TwitterAbi.abi, signer);

                const userPayments = await contract.getAllPayments();


                console.log("userPayments :" + userPayments[0].timeDeployed);
                setDeployments(userPayments);
                /*   const result = await Promise.all(userPayments.map(async (d,index) => {
   
                   let item = {
                       companyId: d[0].companyId,
                   };
   
                   console.log("contractName : " + item.contractName);
                   return item;
   
               }));
   
               setDeployments(result.reverse());
   */

            } catch (error) {
                console.error("Error fetching deployments:", error);
            }
        };

        // Call the function to fetch data when the component mounts
        getAllPayments();
    }, []); // Empty dependency array to run the effect only once when the component mounts

    return (
        <>
            <nav>
                <h1>Dashboard</h1>
                <Link to="/" className='link'>
                    <button className='dash'>Deploy</button>
                </Link>
            </nav>

            <div className="mainContent">
                <header>
                    <h1>User Dashboard</h1>
                </header>

                <main>
                    <section>
                        <h2>Welcome to your dashboard!</h2>
                        <p>You can see all your deployment details here.</p>
                    </section>

                    <section>
                        <h2>Your Deployments</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Company Number</th>
                                    <th>Company Name</th>
                                    <th>Contract Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {deployments.map((deployment, index) => (
                                    <tr key={index}>
                                        <td>{new Date(deployment.timeDeployed * 1000).toLocaleString()}</td>
                                        <td>{deployment.companyId}</td>
                                        <td>{deployment.companyName}</td>
                                        <td>
                                            <a
                                                href={`https://mumbai.polygonscan.com/address/${deployment.contractAddress}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {deployment.contractAddress}
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </main>

                <footer>
                    &copy; 2023 Contract Deployment Dapp
                </footer>
            </div>
        </>
    );
}

export default Dashboard;
