import React, { useState } from 'react';
import axios from 'axios';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { ethers } from 'ethers';
import { Avatar, Loading, useNotification } from "@web3uikit/core";
import Web3Modal from "web3modal";
import './payment.css';

const Register = () => {
    const [response, setResponse] = useState('');
    const [_ContractName, set_ContractName] = useState('');
    const [_ContractSymbol, set_ContractSymbol] = useState('');
    const [_TotalSupply, set_TotalSupply] = useState('');
    const [_TokenTransferAddress, set_TokenTransferAddress] = useState('');
    const [_companyId, set_CompanyId] = useState('');
    const [_companyName, set_CompanyName] = useState('');
    const [_email, set_Email] = useState('');
    const [_signerAddress, setSignerAddress] = useState('');
    const [contractPayment, setcontract] = useState('');
    const notification = useNotification();

    async function paymentDeploy() {
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        //  let provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();
        setSignerAddress(signerAddress);

        const contract = new ethers.Contract(paymentContractAddress, TwitterAbi.abi, signer);
        setcontract(contract);

        try {

            const payment = await contract.deployContract(_companyId, _companyName, _email, { value: '100000000000000' });
            await payment.wait();

            notification({
                type: 'success',
                title: 'Registered Successfully',
                position: 'topR'
            });

            await new Promise(resolve => setTimeout(resolve, 3000));
            window.location.reload();

        } catch (error) {
            notification({
                type: 'error',
                title: 'Transaction Error',
                message: error.message,
                position: 'topR'
            });
        }
    }

    return (
        <>
            <h1>This is the place users do payments</h1>

            <div className='mainDiv'>

                <div>

                    <input required class="mintingAmount" type="text" name="_companyId" placeholder="Company Id" value={_companyId}
                        onChange={event => set_CompanyId(event.target.value)}></input>
                    <input required class="mintingAmount" type="text" name="_companyName" placeholder="Company Name" value={_companyName}
                        onChange={event => set_CompanyName(event.target.value)}></input>
                    <input required class="mintingAmount" type="email" name="_email" placeholder="Email" value={_email}
                        onChange={event => set_Email(event.target.value)}></input>

                </div>

                <button onClick={paymentDeploy}>Register with 0.0001 Matic</button>

            </div>
        </>
    );
};

export default Register;
